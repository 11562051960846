<template>
  <nav>
    <v-navigation-drawer
      app
      :mini-variant="!showNav"
      mini-variant-width="70"
      permanent
      style="z-index:9999"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <router-link :to="{ name: 'Dashboard' }">
                <v-img
                  v-show="showNav"
                  src="@/assets/biodf/BioDF-textlogo.png"
                  max-width="200"
                />
                <v-img
                  v-show="!showNav"
                  src="@/assets/biodf/BioDF-icon.png"
                  max-width="40"
                />
              </router-link>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.route ? item.route : ''"
          exact
          :href="item.href ? item.href : ''"
        >
          <v-list-item-icon>
            <v-icon color="primary" v-if="item.icon">{{ item.icon }}</v-icon>
            <v-img
              v-if="item.image"
              max-width="25"
              :src="require(`@/assets/icon/${item.image}`)"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-group :value="true">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-avatar
                v-if="$store.state.user.userAttributes.picture"
                size="30"
              >
                <v-img :src="$store.state.user.userAttributes.picture">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey darken-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
              <v-avatar
                v-else
                size="30"
                color="primary"
                class="white--text text-caption"
              >
                {{ userInitial }}
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              {{ $store.state.user.userAttributes.name }}
            </v-list-item-content>
          </template>

          <v-list-item :to="{ name: 'EditProfile' }">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="primary--text"
              >Your profile</v-list-item-title
            >
          </v-list-item>
          <v-dialog v-model="dialog" width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-icon>
                  <v-icon color="primary">mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="primary--text"
                  >Logout</v-list-item-title
                >
              </v-list-item>
            </template>
            <Dialog @close-dialog="dialog = false" heading="Log out">
              <v-card-text>
                Are you sure you want to log out?
              </v-card-text>
              <v-btn color="primary" @click="onLogout">
                Logout
              </v-btn>
            </Dialog>
          </v-dialog>
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <transition name="fade">
          <div v-show="showNav" class="mx-4">
            <p class="mb-0">v{{ version }} ({{ timestamp }})</p>
            <a
              class="text-decoration-none primary--text"
              href="https://discourse.bio-conversion.org/c/site-feedback/2"
              >Have feedback?</a
            >
          </div>
        </transition>

        <v-btn icon @click.stop="showNav = !showNav" class="mx-3 my-4">
          <v-icon color="primary">
            mdi-menu
          </v-icon>
        </v-btn>
      </template>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { version } from '../../package.json'
import { timestamp } from '@/assets/data/timestamp.json'
import Dialog from '@/components/Dialog'

export default {
  name: 'App',
  components: {
    Dialog
  },
  data: () => ({
    dialog: false,
    version,
    timestamp,
    user: null,
    username: '',
    showNav: false,
    items: [
      {
        title: 'Home',
        icon: 'mdi-home',
        route: { name: 'Dashboard' }
      },
      {
        title: 'Analytics',
        icon: 'mdi-poll',
        route: { name: 'Analytics' }
      },
      {
        title: 'Manage Users',
        icon: 'mdi-clipboard-account',
        route: { name: 'ManageUsers' }
      },
      {
        title: 'Data Entry',
        icon: 'mdi-database',
        route: { name: 'DataEntry' }
      }
    ]
  }),
  computed: {
    userInitial() {
      let name = this.$store.state.user.userAttributes.name
      if (name === undefined) {
        return ' '
      }
      let names = name.split(' ')
      let initial = ''
      for (let i = 0; i < names.length; i++) {
        initial += names[i][0]
      }
      return initial.toUpperCase()
    }
  },
  methods: {
    onLogout() {
      this.$router.push({ name: 'Logout' })
    }
  }
}
</script>

<style scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 1.5s;
}
</style>
